.carousel {
  &__slide {
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    position: absolute;
    width: 100vw;
    will-change: transform, opacity;
    text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
  }

  &__content {
    height: $carousel-height;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 50%;
  }

  &__nav {
    display: flex;
    position: absolute;
    left: 50%;
    bottom: 0;
  }

  &__nav-bullet {
    width: $base-font-size / 1.5;
    height: $base-font-size / 1.5;
    margin: 1.5 * $base-font-size 1.5 * $base-font-size;
    border-radius: 50%;
    background: $inactive-color;
  }

  &__accent {
    font-size: $base-font-size * 6;
    margin-left: -0.055em;
    transition: all ease 0.5s;
    @include lineHeightCrop (
      var(--heading-line-height),
      var(--font-primary-capital-letter),
    );
    &:hover {
      color: $inactive-color;
      cursor: pointer;
      text-decoration: none;
    }
  }

  &__text {
    font-size: $base-font-size * 3;
    margin-left: 0.1em;
    color: $inactive-color;
    @include lineHeightCrop (
      var(--heading-line-height),
      var(--font-primary-capital-letter),
    );
  }
  // &__rightArrow {
  //   width: 30px;
  //   height: 30px;
  //   fill: $primary-color;
  //   transition: all ease 0.5s;
  //   padding-bottom: 0.75 * $base-font-size;
  //   padding-left: 20px;
  //   &:hover {
  //     fill: $inactive-color;
  //     padding-left: 40px;
  //   }
  // }
}

.active_bullet {
  background: $primary-color;
}

.first_slide {
  background: url('./images/carousel_bg1.jpg');
  background-size: cover;
  background-position: right bottom;
}

.second_slide {
  background: url('./images/carousel_bg1.jpg');
  background-size: cover;
  background-position: left top;
}

.third_slide{
  background: url('./images/carousel_bg1.jpg');
  background-size: cover;
  background-position: right;
}
