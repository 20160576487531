.header {
  height: 75px;
  display: flex;
  font-weight: bold;
  box-sizing: border-box;
  padding: 0 15px 0 15px;
  justify-content: space-between;
  // text-shadow: 0.5px 0.5px 0.5px #555;

  &__logo {
    display: flex;
    font-size: $base-font-size * 2;
    margin: 0;
    align-self: center;
    @include lineHeightCrop (
      var(--heading-line-height),
      var(--font-primary-capital-letter),
    );
  }

  &__menu {
    list-style: none;
    display: flex;
    font-size: 1.33 * $base-font-size;
    padding: 0;
    margin: 0;
    align-self: center;
  }

  &__menu-item {
    list-style: none;
    display: flex;
  }

  &__menu-link {
    color: $primary-color;
    text-decoration: none;
    &:hover {
      border-bottom: solid 2px $primary-color;
    }
  }

  &__menu-splitter {
    padding: 0 15px;
    text-shadow: 1px 1px 1px #000;
  }

  &__contacts {
    white-space: nowrap;
    font-size: 1.33 * $base-font-size;
    align-self: center;
  }
}

.currentPage {
  border-bottom: solid 2px red;
  color: $inactive-color;
}
