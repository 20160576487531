$bgcolor: #fff;
$primary-color: #ea214f;
$inactive-color: #666666;
$base-font-size: 1vmax;
$carousel-height: 33vmax;

@mixin lineHeightCrop($line-height, $capital-letter: 1) {
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.48em);
  }
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-bottom: calc((#{$capital-letter} - #{$line-height}) * 0.55em);
  }
}

body {
    margin: 0;
    padding: 0;
    min-width: 320px;
    height: 100vh;
    background: $bgcolor;
    color: $primary-color;
    font-family: Roboto Condensed;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    overflow-x: hidden;
}

a:link, a:visited, a:active {
  color: $inactive-color;
}

a:hover {
  text-decoration: none;
  color: $primary-color;
}

:root {
  --font-primary: 'Roboto Condensed', sans-serif;
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
  --font-primary-capital-letter: 0.75;
}

@import 'Header';
@import 'Carousel';
@import 'Content';
